.mnav {
    display: flex;
    padding: 24px;
    background: #ffffff;
    backdrop-filter: blur(30px);
    box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.16);
    flex-wrap: wrap;
}

.mnav-parents {
    width: 30%;
    padding: 0 24px;
}

.mnav-parents ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.mnav-parents ul li {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;;
}

.mnav-parents ul li:first-of-type {
    padding-top: 0;
}

.mnav-parents ul li > a {
    display: block;
    position: relative;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
}

.mnav-parents ul li > a:hover {
    color: #5fa752;
}

.mnav-parents ul li.has-children > a:after {
    content: "\e919";
    font-family: icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 0;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
}

.mnav-children {
    padding: 0;
    width: 70%;
    position: relative;
}

.mnav-children__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
}

.mobile-menu.opened .mnav-children__inner.opened {
    opacity: 1;
    visibility: visible;
}


.mnav-children__item {
    width: 25%;
    padding: 30px 24px;
    text-align: center
}

.mnav-children__item:hover .title {
    color: #5fa752;
}

@media (min-width: 1024px) {
    .mnav-children__item .title {
        display: block;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        color: #484848;
        margin-top: 36px;
    }

    .mobile-menu .mnav-children__inner.opened {
        visibility: visible !important;
        opacity: 1;
    }

    .mnav {
        min-height: 580px;
        padding: 24px 24px 0 24px;
    }

    .mnav-parents ul li > a {
        padding-right: 10px;
    }
}

.mnav-children__item .img {
    width: 100%;
    display: flex;
    align-items: center;
    height: 130px;
}

.mnav-children__item img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    width: auto;
}

@media (max-width: 1023px) {
    .mnav-parents,
    .mnav-children__inner {
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: none;
        background: #012d41;
        min-height: 100%;
        padding: 0 30px 30px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 10;
        border: 0;
        transform: translate(0);
    }

    .mnav-children {
        width: 100%;
    }

    .mnav-children__inner {
        visibility: hidden;
        opacity: 0;
        max-height: 100vh;
        display: block;
        left: -30px;
        right: -30px;
    }

    .mobile-menu.opened .mnav-children__inner.opened {
        visibility: visible;
        opacity: 1;
    }

    .mnav-children__item,
    .mnav-children a {
        display: block;
        width: 100%;
        font-family: Montserrat;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        color: #fff;
        position: relative;
        text-align: left;
        padding: 0 0 25px;
        border: 0;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
    }

    .mnav-children__item .img {
        display: none;
    }

    .mnav-parents ul li {
        border: 0;
    }

    .mnav-parents ul li a {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
    }

    .navigation ul .navigation-drop.mnav.back-submenu,
    .navigation ul .navigation-drop.mnav.back-menu {
        color: #1ba5b8 !important;
    }

    .navigation ul .navigation-drop.mnav .back-submenu .icon,
    .navigation ul .navigation-drop.mnav .back-menu .icon {
        margin-right: 20px;
        color: #fff;
        font-size: 17px;
        position: static;
        display: inline-block;
    }
}

.catalog-tabs {
    display: flex;
    justify-content: stretch;
    width: 100%;
}

.catalog-tabs a {
    border: 1px solid #5ea652;
    color: #5ea652;
    margin-right: -1px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.catalog-tabs a:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.catalog-tabs a:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.catalog-tabs a:hover {
    border-color: #d32c2e;
    color: #d32c2e;
    z-index: 1;
}

.catalog-tabs a.current {
    color: #ffffff !important;
    border-color: #5ea652 !important;
    background: #5ea652 !important;
}

.catalog-tabs__content {
    display: none;
    padding: 36px 0;
}

.catalog-tabs__content.current {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.catalog-tabs__content .item {
    display: block;
    width: calc(100% / 6);
    padding: 18px;
}

.catalog-tabs__content .item a {
    display: block;
    text-decoration: none;
    border: 0;
    text-align: center
}

.catalog-tabs__content .item a .img {
    margin-bottom: 12px;
    height: 130px;
    display: flex;
    align-items: center;
    width: 100%;
}

.catalog-tabs__content .item a .img img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    width: auto;
}

@media (max-width: 1023px) {
    .catalog-tabs__content .item {
        width: 25%;
    }

    .catalog-tabs a {
        font-size: 12px;
        line-height: normal;
        padding: 5px 10px;
    }

}

@media (max-width: 767px) {
    .catalog-tabs__content .item {
        width: 33.334%;
    }

    .catalog-tabs {
        flex-wrap: wrap;
    }

    .catalog-tabs a {
        width: 100%;
        border-radius: 0 !important;
        display: none;
        overflow: hidden;
        position: relative;
        margin: 0 !important;
    }

    .catalog-tabs a.current:after {
        width: 0;
        height: 0;
        border: 0 solid transparent;
        border-left-width: 4px;
        border-right-width: 4px;
        border-top: 4px solid #fff;
        content: "";
        display: block;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media (max-width: 567px) {
    .catalog-tabs__content .item {
        width: 50%;
    }
}
